import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <div id="footer">
    </div>
  `,
  styles: ['#footer{background: #263238;padding:20px 0px;}'
  ] })
export class FooterComponent implements OnInit {

  constructor(

  ) {

  }

  ngOnInit() {

  }

}
