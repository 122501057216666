import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Movement, CartMovement } from '../@models';

export class MovementGroup{
    _id:string;
    count:number;
    quantity:number;
}

@Injectable({
  providedIn: 'root'
})
export class MovementService {
    constructor(private http: HttpClient) { }

    baseUrl: string = '/api/movement';

    create(fields) {
        return this.http.post(this.baseUrl, fields);
    }

    list() {
        return this.http.get<Movement[]>(this.baseUrl);
    }

    find(fields) {
        return this.http.post<Movement[]>(this.baseUrl+"/find", fields);
    }

    findCart(fields) {
        return this.http.post<CartMovement>(this.baseUrl+"/findCart", fields);
    }

    listCart(fields) {
        return this.http.post<CartMovement[]>(this.baseUrl+"/listCart", fields);
    }

    updateCart(cartId, fields) {
        return this.http.put(this.baseUrl+"/updateCart/"+cartId, fields);
    }

    findByGroup(fields) {
        return this.http.post<MovementGroup[]>(this.baseUrl+"/findByGroup", fields);
    }

    reportExcel(fields){
        return this.http.post(this.baseUrl+"/reportExcel", fields);
    }

    reportExcelArticle(fields){
        return this.http.post(this.baseUrl+"/reportExcelArticle", fields);
    }

    reportPDF(fields){
        return this.http.post(this.baseUrl+"/reportPDF", fields);
    }

    reportPDFArticle(fields){
        return this.http.post(this.baseUrl+"/reportPDFArticle", fields);
    }

    downloadFile(file){
        return this.http.get(this.baseUrl+"/downloadFile/"+file);
    }

    confirmMovement(fields){
        return this.http.post(this.baseUrl+"/confirmMovement", fields);
    }

    rejectMovement(fields){
        return this.http.post(this.baseUrl+"/rejectMovement", fields);
    }

    show(movementId:string) {
        return this.http.get<Movement>(this.baseUrl+"/"+movementId);
    }

    update(movementId:string, fields) {
        return this.http.put(this.baseUrl+"/"+movementId, fields);
    }

    remove(movementId) {
        return this.http.delete(this.baseUrl+"/"+movementId);
    }

}
