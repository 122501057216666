import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Task } from '../@models';

export class TaskResult{
    docs:Task[];
    count:number;
}

@Injectable({
  providedIn: 'root'
})
export class TaskService {
    constructor(private http: HttpClient) { }

    baseUrl: string = '/api/task';

    create(fields) {
        return this.http.post(this.baseUrl, fields);
    }

    list() {
        return this.http.get<TaskResult>(this.baseUrl);
    }

    find(fields) {
        return this.http.post<TaskResult>(this.baseUrl+"/find", fields);
    }

    getByYear() {
        return this.http.get<any[]>(this.baseUrl+"/getYears");
    }

    show(taskId:string) {
        return this.http.get<Task>(this.baseUrl+"/"+taskId);
    }

    update(taskId:string, fields) {
        return this.http.put(this.baseUrl+"/"+taskId, fields);
    }

    confirm(taskId:string, fields) {
        return this.http.put(this.baseUrl+"/confirm/"+taskId, fields);
    }

    remove(taskId) {
        return this.http.delete(this.baseUrl+"/"+taskId);
    }

}
