import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  template: `
    <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <a class="navbar-brand" [routerLink]="['/']">
        RED
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/orgs/list']">Organizaciones</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Cuenta
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/auth/logout']">Cerrar sesión</a>
            </div>
          </li>
         </ul>
      </div>
    </nav>
  `,
  styles: ['a{cursor:pointer}',
  '.navbar-brand .logoNormal{ width:30%;}',
  '.navbar-brand .logoAdmin{ width:80%;}',
  '.navbar-brand{ padding:0px;margin:0px; }'] })
export class HeaderComponent implements OnInit {

  constructor(
    private _router:Router
  ) {

  }

  ngOnInit() {
    
  }


}
