import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService, PreviousRouteService } from '../@services';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private _errorservice:ErrorService,
        private _routeservice:PreviousRouteService,
        private _routerservice:Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                console.log(err);
                location.reload(true);
            }else if(err.status == 407){
                this._routerservice.navigate(['/auth/logout']);
            }else{            
                const error = err.error.message || err.statusText;
                //console.log(err, request);
                if( request.url != "/api/auth/error" && (err.status == 400 || err.status == 413) ){
                    //console.log(err);
                    if((err.error && err.error.error && err.error.error.code == 190)){
                        //return throwError(err);
                        console.log("Erro graph");
                    }else{
                        const routes = this._routeservice.getPreviousUrl();
                        const userId = (localStorage.sessionInventory) ? JSON.parse(localStorage.sessionInventory)._id : "";
                        this._errorservice.send(err, request, routes, userId).subscribe(
                            (data) => {
                                console.log(data);
                            }, (error) => {
                                console.log(error);
                            }
                        );
                    }
                }
            }


            return throwError(err);
        }))
    }
}
