import { Component, OnInit, Input } from '@angular/core';
import { UserService, MovementService } from '../../@services';
import { User, CartMovement, Movement } from '../../@models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-layout',
  template: `
    <div class="main-container">
      <div class="wrapper">
      <nav id="sidebar" [className]="isSidebarCollapsed ? 'active':''">
        <div class="sidebar-header">
          <span class="company-name d-none d-sm-none d-md-block">USMEF</span>
          <strong class="d-none d-sm-none d-md-block">Inventario</strong>
        </div>
        <ul class="list-unstyled components">
          <li [ngClass]="{'active': checkActive('groups')}">
            <a [routerLink]="['/groups/list']">
              <i class="fas fa-users"></i>
              <span class="menu-text">Áreas</span>
            </a>
          </li>
          <li [ngClass]="{'active': checkActive('jobs')}">
            <a [routerLink]="['/jobs/list']" *ngIf="userRole == 1">
              <i class="fas fa-user-tag"></i>
              <span class="menu-text">Puestos</span>
            </a>
          </li>
          <li [ngClass]="{'active': checkActive('articles/')}">
            <a [routerLink]="['/articles/list']">
              <i class="fas fa-list"></i>
              <span class="menu-text">Artículos</span>
            </a>
          </li>
          <li [ngClass]="{'active': checkActive('tasks/list')}">
            <a [routerLink]="['/tasks/list']" *ngIf="userRole == 1 || (user.permissions && userPermissions.includes('asignacion'))">
              <i class="fas fa-clipboard-list"></i>
              <span class="menu-text">Asignar Tareas</span>
            </a>
          </li>
          <li [ngClass]="{'active': checkActive('assigned')}">
            <a [routerLink]="['/tasks/assigned/list']">
              <i class="fas fa-calendar-check"></i>
              <span class="menu-text">Tareas asignadas</span>
            </a>
          </li>
          <li [ngClass]="{'active': checkActive('history')}">
            <a [routerLink]="['/tasks/history']" *ngIf="userRole == 1">
              <i class="fas fa-history"></i>
              <span class="menu-text">Historial tareas</span>
            </a>
          </li>
          <li [ngClass]="{'active': checkActive('movements/list')}">
            <a [routerLink]="['/movements/list']" *ngIf="userRole == 1 || (user.permissions && userPermissions.includes('autorizacion'))">
              <i class="fas fa-envelope-open-text"></i>
              <span class="menu-text">Solicitudes</span>
            </a>
          </li>
          <li [ngClass]="{'active': checkActive('reports')}">
            <a (click)="isCollapseReport = !isCollapseReport" aria-controls="collapseReport">
              <i class="fas fa-chart-bar"></i>
              <span class="menu-text">Reportes</span>
            </a>
          </li>
          <div [ngbCollapse]="isCollapseReport" id="collapseReport">
            <ul class="list-unstyled">
              <li [ngClass]="{'active': checkActive('reports/movements')}">
                <a [routerLink]="['/reports/movements']">
                  <i class="fas fa-envelope-open-text"></i>
                  <span class="menu-text">Movimientos</span>
                </a>
              </li>
              <li [ngClass]="{'active': checkActive('reports/articles')}">
                <a [routerLink]="['/reports/articles']">
                  <i class="fas fa-list"></i>
                  <span class="menu-text">Artículos</span>
                </a>
              </li>
            </ul>
          </div>
          <li [ngClass]="{'active': checkActive('users')}">
            <a [routerLink]="['/users/list']" *ngIf="userRole == 1">
              <i class="fas fa-user"></i>
              <span class="menu-text">Usuarios</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/auth/logout']">
              <i class="fas fa-sign-out-alt"></i>
              <span class="menu-text">Cerrar sesión</span>
            </a>
          </li>
        </ul>
      </nav>
      <div id="content">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
              <div class="container-fluid">
                  <div class="d-flex align-items-center">
                    <button type="button" id="sidebarCollapse" class="btn btn-info" (click)="isSidebarCollapsed = !isSidebarCollapsed">
                        <i class="fas fa-align-left"></i>
                        <span>Toggle Sidebar</span>
                    </button>
                    <div class="d-block d-sm-block d-md-none ml-2">
                      <span class="company-name">USMEF</span>
                      <strong>Inventario</strong>
                    </div>
                  </div>
                  <div class="row align-items-center user-options">
                    <div class="col-6 col-md-6">
                      <a class="btn btn-link d-flex align-items-center text-dark" title="Solicitudes de artículos" (click)="openModal(cartDetail)">
                        <span class="badge badge-pill badge-dark">{{ cart.movements ? cart.movements.length : 0 }}</span>
                        <p class="mb-0 ml-2">Carrito</p>
                      </a>
                    </div>
                    <div class="col-6 col-md-6">
                      <p>{{ user.name+" "+user.lastname }}</p>
                    </div>
                  </div>
              </div>
          </nav>
          <ng-content></ng-content>
      </div>
      <app-footer></app-footer>
    </div>
    <ng-template #cartDetail let-modal>
      <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Carrito de artículos</h3>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table" *ngIf="cart && cart.movements">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Artículo</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of cart.movements">
                        <th scope="row">
                          <p class="text-dark">{{ item.article.name }}</p>
                        </th>
                        <td>
                          <p class="text-dark">{{ item.quantity }}</p>
                        </td>
                        <td>
                          <button class="btn btn-danger" (click)="remove(item, deleteModal)">
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                  <div *ngIf="!cart || !cart.movements">
                    <div class="alert alert-warning alert-dismissible form-response-status-warning ml-2" role="alert">
                      <strong>No hay artículos agregados</strong>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
            (click)="openModal(confirmRequestModal)" *ngIf="cart && cart.movements && cart.movements.length > 0">
            Enviar solicitud
        </button>
        <button type="button" class="btn btn-danger"
            (click)="modal.dismiss()" >
                Cerrar
        </button>
      </div>
    </ng-template>
    <ng-template #deleteModal let-modal>
      <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Remover artículo</h3>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-12">
                  <p>¿Está seguro de eliminar el artículo?</p>
              </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
            (click)="confirmRemove()">
            Eliminar
        </button>
        <button type="button" class="btn btn-danger"
            (click)="modal.dismiss()" >
            Cancelar
        </button>
      </div>
    </ng-template>
    <ng-template #confirmRequestModal let-modal>
      <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Enviar solicitud de artículos</h3>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-12">
                  <p>¿Está seguro de enviar la solicitud de los artículos?</p>
              </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
            (click)="sendRequest(requestMessageModal)">
            Sí
        </button>
        <button type="button" class="btn btn-danger"
            (click)="modal.dismiss()" >
            No
        </button>
      </div>
    </ng-template>
    <ng-template #requestMessageModal let-modal>
      <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Enviar solicitud de artículos</h3>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-12">
                  <p>Su solicitud se ha realizado con éxito. <br>Posteriormente llegará a su cuenta de correo una notificación sobre el estatus de la solicitud</p>
              </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger"
            (click)="modal.dismiss()" >
            Cerrar
        </button>
      </div>
    </ng-template>
  `,
  styles: ['.content-container{min-height:70vh;}','p{margin:0px}','.collapse a{background:inherit;}','.collapse a:hover{background:white;}','a{cursor:pointer}']
})
export class AppLayoutComponent implements OnInit {

  title = 'inventario';
  public isSidebarCollapsed = false;
  user: User = new User();
  userRole:number;
  userId:string;
  isCollapseReport = true;
  userPermissions:string[];
  cart:CartMovement = new CartMovement();
  modalReference:any;
  modal2Reference:any;
  selectedItem:Movement;

  constructor(
    private _service:UserService,
    private _movementservice:MovementService,
    private modalService:NgbModal,
    private _loader:NgxUiLoaderService
  ) { 
  }

  ngOnInit(){
    this.userPermissions = sessionStorage.permissions ? JSON.parse(sessionStorage.permissions) : [];
    this.userRole = sessionStorage.role;
    let user = localStorage.sessionInventory ? JSON.parse(localStorage.sessionInventory)._id : null;
    this._service.show(user).subscribe(
      (data) => {
        this.user = data;
        sessionStorage.role = this.user.role;
        sessionStorage.permissions = JSON.stringify(data.permissions);
        this.userPermissions = JSON.parse(sessionStorage.permissions);
        this.userRole = sessionStorage.role;
        this.userId = data._id;
        this.getCart( data._id );
      }
    );

  }

  getCart(userId:string){
    this._movementservice.findCart({userId:userId}).subscribe(
      (data) => {
        this.cart = data;
        console.log(this.cart);
      }, (error) => {
        this.cart = new CartMovement();
      }
    );
  }

  openModal(content){
    this.getCart(this.userId);
    this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'});
  }  

  remove(item, content){
    this.selectedItem = item;
    this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'});
  }

  confirmRemove(){
    this._movementservice.remove(this.selectedItem._id).subscribe(
      (data) => {
        this.modalReference.close();
        this.getCart(this.userId);
      }, (error) => {

      }
    );
  }

  sendRequest(content){
    this._loader.start();
    this._movementservice.updateCart(this.cart._id, {is_sent:true}).subscribe(
      (data) => {
        this._loader.stop();
        this.modalReference.close();
        this.openModal(content);
        this.getCart(this.userId);
      }, (error) => {
        this._loader.stop();
      }
    );
  }

  checkActive(id){
    const url = window.location.href;
    return url.indexOf(id) > -1;
  }

}
