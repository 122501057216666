import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './@core';

const routes: Routes = [{
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },{
      path: 'users',
      loadChildren: './users/users.module#UsersModule',
      canLoad : [AuthGuard]
  },{
      path: 'articles',
      loadChildren: './articles/articles.module#ArticlesModule',
      canLoad : [AuthGuard]
  },{
      path: 'branches',
      loadChildren: './branches/branches.module#BranchesModule',
      canLoad : [AuthGuard]
  },{
      path: 'tasks',
      loadChildren: './tasks/tasks.module#TasksModule',
      canLoad : [AuthGuard]
  },{
      path: 'jobs',
      loadChildren: './jobs/jobs.module#JobsModule',
      canLoad : [AuthGuard]
  },{
      path: 'groups',
      loadChildren: './groups/groups.module#GroupsModule',
      canLoad : [AuthGuard]
  },{
      path: 'movements',
      loadChildren: './movements/movements.module#MovementsModule',
      canLoad : [AuthGuard]
  },{
      path: 'reports',
      loadChildren: './reports/reports.module#ReportsModule',
      canLoad : [AuthGuard]
  },{ path: '**', redirectTo: 'auth' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
