import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../@models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) { }

    baseUrl: string = '/api/user';

    create(fields) {
        return this.http.post(this.baseUrl, fields);
    }

    list() {
        return this.http.get<User[]>(this.baseUrl);
    }

    find(fields) {
        return this.http.post<User[]>(this.baseUrl+"/find", fields);
    }

    show(userId:string) {
        return this.http.get<User>(this.baseUrl+"/"+userId);
    }

    update(userId:string, fields) {
        return this.http.put(this.baseUrl+"/"+userId, fields);
    }

    remove(userId) {
        return this.http.delete(this.baseUrl+"/"+userId);
    }

    checkAdmin(fields){
        return this.http.post<any>(this.baseUrl+"/checkAdmin", fields);
    }

    retrievePassword(fields){
         return this.http.post<any>(this.baseUrl+"/retrievePassword", fields);   
    }

    getUserInfo(){
        let userId = localStorage.sessionInventory ? JSON.parse(localStorage.sessionInventory)['_id'] : null;
        return this.http.get<User>(this.baseUrl+"/"+userId);
    }

}
