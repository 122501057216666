import { Component, OnInit } from '@angular/core';
import { AuthService  } from './@services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'inventory';

  constructor(
  	private _service:AuthService,
  	private _router:Router
  ){

  }

  ngOnInit(){
  	console.log('Component');
  	/*if(localStorage.sessionInventory && localStorage.getItem('sessionInventoryToken')){
  		this._service.me( localStorage.getItem('sessionInventoryToken') ).subscribe(
  			(data) => {
  				//localStorage.tokenInventory = data["token"];
  			}, (error) => {
  				this._router.navigate(["/auth/logout"]);
  			}
  		);
  	}else{
  		this._router.navigate(["/auth/logout"]);
  	}*/
  }

}
