export class User{
	_id : string;
	name   : string;
    lastname    : string;
    email       : string;
    role        : number;
    job:string;
    group:string;
    token : string;
    status : string;
    permissions: string[];
    created_at : string;
}