import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}
  me() {
    return this.http.get<any>(`/api/auth/me`);
  }
  signIn(fields) {
    return this.http.post<any>(`/api/auth/signin`, fields);
  }

  setSession(data, token) {
    localStorage.sessionInventory = JSON.stringify(data);
    localStorage.sessionInventoryToken = token;
    //document.cookie = "tokenInventory="+token;
  }
}
