import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ErrorService {

  constructor(private http: HttpClient) { }
  baseUrl: string = '/api/auth/error';

  send(error, request, routes, userId){
    return this.http.post(this.baseUrl, {error: error, request:request, routes: routes, userId: userId});
  }
}
