import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Article, ArticleList } from '../@models';

export class ArticleResult{
    docs:Article[];
    count:number;
}
export class ArticleResultModified{
    docs:ArticleList[];
    count:number;
}

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
    constructor(private http: HttpClient) { }

    baseUrl: string = '/api/article';

    create(fields) {
        return this.http.post(this.baseUrl, fields);
    }

    list() {
        return this.http.get<ArticleResult>(this.baseUrl);
    }

    find(fields) {
        return this.http.post<ArticleResultModified>(this.baseUrl+"/find", fields);
    }

    sendRemoveNotification(articleId){
        return this.http.post(this.baseUrl+"/removeNotification", {articleId:articleId, userId: localStorage.sessionInventory ? JSON.parse(localStorage.sessionInventory)['_id'] : null});
    }

    requestArticle(fields){
        return this.http.post(this.baseUrl+"/requestArticle", fields);
    }

    listStores() {
        return this.http.get<string[]>(this.baseUrl+"/listStores");
    }

    show(articleId:string) {
        return this.http.get<Article>(this.baseUrl+"/"+articleId);
    }

    update(articleId:string, fields) {
        return this.http.put(this.baseUrl+"/"+articleId, fields);
    }

    remove(articleId, fields) {
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body: fields
        };
        return this.http.delete(this.baseUrl+"/"+articleId, options);
    }

}
