export class Movement{
	_id:string;
	quantity : string;
    type : string;
    article : string;
    requested_by : string;
    validated_by : string;
    comment:string;
    evidence:string;
    status : boolean;
    is_rejected : boolean;
    is_deleted : boolean;
    created_at  : string;
}