import { Injectable } from '@angular/core';
import { Router, Route, CanLoad } from '@angular/router';
import { AuthService } from '../@services';

@Injectable()
export class AuthGuard implements CanLoad {

    constructor(
        private router: Router,
        private _service:AuthService
    ) { 
    }

    canLoad(route: Route) : boolean  {
        if (localStorage.getItem('sessionInventory')) {
            // logged in so return true
            return true;
        }else{
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/auth/logout']);
            return false;
        }
    }
}
